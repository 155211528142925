.netSpeed {
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: 400px;
  padding: 20% 5% 5%;
  position: relative;

  .checkBtn {
    background: none;
    border: 2px solid #8883a5;
    border-radius: 25px;
    color: #8883a5;
    cursor: pointer;
    font-size: 20px;
    font-weight: 600;
    height: 60px;
    outline: none;
    padding: 10px 20px;
    transition: all .4s ease-in;
    -webkit-user-select: none;
    user-select: none;
    width: 120px;

    &:hover {
      background: #8883A5;
      color: white;
    }
  }

  .loading {
    background-color: transparent;
    border: 4px solid #ABA8AF;
    border-top-color: #4C2F80;
    user-select: none;
    font-size: 0;
    outline: none;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    animation: rotate 0.8s linear infinite;
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg)
    }

    ;

    100% {
      transform: rotate(360deg)
    }

    ;
  }

}