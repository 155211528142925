.numpad{
  display: grid;
  display: grid;
  /* padding: 30px; */
  /* margin: 5px; */
  font-weight: 900;
  grid-template-columns: repeat(5,4.6em);
  grid-template-rows: repeat(6,4.6em);
}


.input{
  width: 12.85em;
  height: 2.5em;
  font-size: 21pt;
  overflow-y: scroll;
  direction: ltr;
  text-align: center;
}


.calcBtn {
appearance: none;
background-color: transparent;
border: 2px solid #1A1A1A;
border-radius: 15px;
box-sizing: border-box;
color: #3B3B3B;
cursor: pointer;
display: inline-block;
font-family: Roobert,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
font-size: 20px;
font-weight: 600;
line-height: normal;
outline: none;
text-align: center;
text-decoration: none;
transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
user-select: none;
-webkit-user-select: none;
touch-action: manipulation;
will-change: transform;
}

.calcBtn:disabled {
pointer-events: none;
}

.calcBtn:hover {
color: #fff;
background-color: #1A1A1A;
box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
transform: translateY(-2px);
}

.calcBtn:active {
box-shadow: none;
transform: translateY(0);
}

.history{
  display: flex;
  flex-direction: column-reverse;
  width: 12.85em;
  height: 2.5em;
  font-size: 21pt;
  overflow-y: scroll;
  overflow-x: scroll;
  scroll-behavior: smooth;
}

.equal {
    width: 400%;
}