@import url('https://fonts.googleapis.com/css2?family=Cairo+Play&display=swap');

.navbar {
  min-width: 250px;
  height: 100%;
  position: fixed;
  background: white;
  box-shadow: 0px 0px 10px var(--clr-gray200);
  transition: all 0.3s ease-in-out; // Add transition for smooth resizing
  z-index: 100000;

  .header {
    margin: 0;
    margin-right: 1rem;

    a {
      color: #000;
      font-family: Cairo Play, cursive;
      font-size: 3rem;
      list-style: none;
      text-decoration: none;
    }
  }

  &__list {
    margin: 0;
    margin: 2rem 3rem;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    transition: all 0.3s ease-in-out; // Add transition for smooth resizing
  }

  &__item {
    list-style: none;
    font-size: 1.3rem;
    text-align: right;
    transition: all 200ms ease-in;

    svg {
      fill: var(--clr-gray400);
      width: 2rem;
      transition: all 200ms ease-in;

      &:hover {
        fill: var(--clr-gray500);
      }
    }

    a {
      color: var(--clr-gray400);
      text-decoration: none;

      &:hover {
        color: var(--clr-gray500);
      }
    }
  }

  // Add a hamburger menu icon for smaller screens
  &__hamburger {
    display: none; // Hide by default on larger screens
    position: absolute;
    top: 0;
    left: 1rem;
    cursor: pointer;
    height: 90px;

    span {
      display: block;
      width: 25px;
      height: 3px;
      background-color: #333;
      margin-bottom: 5px;
      transition: all 0.3s ease-in-out;
    }
  }

  // Style for the open hamburger menu
  &.open {
    height: 100vh; // Expand to full height when open

    .navbar__hamburger span:nth-child(1) {
      transform: translateY(8px) rotate(45deg);
    }

    .navbar__hamburger span:nth-child(2) {
      opacity: 0;
    }

    .navbar__hamburger span:nth-child(3) {
      transform: translateY(-8px) rotate(-45deg);
    }

    &__list {
      flex-direction: column;
      align-items: flex-start; // Align items to the left

      // Style the hamburger icon when open
    }
  }
}

@media screen and (max-width: 768px) {
  .navbar {
    height: auto; // Adjust height automatically on smaller screens
    width: 100%;

    .header {
      font-size: 14px;
    }

    &__list {
      flex-direction: column;
      width: 100%;
      height: 0; // Initially hide the list
      overflow: hidden; // Hide overflow
      // transition: height 0.3s ease-in-out; // Add transition for smooth opening
      display: none;
    }

    &.open {
      .navbar__list {
        display: flex;
        height: 100%; // Show the list when open
      }
    }

    &__hamburger {
      justify-content: center;
      display: flex;
      flex-direction: column;
    }
  }
}