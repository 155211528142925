.news {
  display: flex;
  flex-direction: column;
  &>* {
   display: flex;
   flex-direction: column; 
  }

  &__grid{
    display: grid; 
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); 
  gap: 50px; 
  margin-left: 100px;
  }

  &__item{
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}
